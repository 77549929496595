import "parsleyjs/src/parsley.js";
import "parsleyjs/src/i18n/fr.js";
import "parsleyjs/src/i18n/es.js";
import "parsleyjs/src/i18n/it.js";
import "parsleyjs/src/i18n/de.js";
import "./parsley";
import "jquery-ui/dist/jquery-ui.js";
import "@hotwired/turbo-rails";
// import "jquery-smooth-scroll/jquery.smooth-scroll.js";
// import "bootstrap/dist/js/bootstrap.bundle.js";
import "moment/src/moment.js";
import "moment/src/locale/de.js";
import "moment/src/locale/es.js";
import "moment/src/locale/fr.js";
import "moment/src/locale/it.js";
// import "flexslider/jquery.flexslider.js";
import "@vendor/jquery.rwdImageMaps.js";
import "@vendor/jquery.capsChecker.js";
import "@vendor/handlebars-v1.2.0";
import "bootstrap-select";
import "./navbar";
import "./login";
import "./forms";
import "./registration";


// Select all links with hashes
$('a.scrollAnimation[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function (event) {
  // On-page links
  if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: target.offset().top,
        },
        1000,
        function () {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) {
            // Checking if the target was focused
            return false;
          } else {
            $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        }
      );
    }
  }
});